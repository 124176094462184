import * as React from 'react';
import {Card} from 'react-bootstrap';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {MouseEventHandler} from 'react';

interface MobileTileProps {
    href?: string;
    icon: IconDefinition;
    label: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    subLabel?: string;
}

export const MobileTile = ({href, icon, label, onClick, subLabel}: MobileTileProps) => {
    return (
        <a
            className="tw-block hover:tw-no-underline tw-text-current hover:tw-text-current tw-mb-2"
            href={href}
            onClick={onClick}
        >
            <Card>
                <div className="tw-flex tw-justify-between tw-items-center tw-mx-4 tw-my-6">
                    <FontAwesomeIcon
                        icon={icon}
                        size="xl"
                        suppressHydrationWarning
                    />
                    <div className="tw-grow tw-text-left tw-ml-4">
                        <div className="tw-font-bold">{label}</div>
                        <div className="tw-text-gray-400">{subLabel}</div>
                    </div>
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        size="xl"
                        suppressHydrationWarning
                    />
                </div>
            </Card>
        </a>
    );
};
