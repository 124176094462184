import * as React from 'react';

import PromotionTracker from '../analytics/promotion-tracker/PromotionTracker';
import {GaPromotion} from '../../client/ga/ga-ecommerce.functions';
import {ResponsiveBannerImage} from '../cms/cms.types';
import {ResponsiveImagePromoStyles} from './ResponsiveImagePromo.styles';

interface ResponsiveImagePromoProps {
    href: string;
    image: ResponsiveBannerImage;
    promotion: GaPromotion;
}

export const ResponsiveImagePromo = ({image, href, promotion}: ResponsiveImagePromoProps) => {
    if (!(image?.small?.url || image?.medium?.url)) {
        return <></>;
    }
    return (
        <ResponsiveImagePromoStyles>
            <PromotionTracker
                classes="responsiveBannerWrap"
                promotion={promotion}
            >
                <a href={href}>
                    <picture>
                        <source
                            media="(max-width: 480px)"
                            srcSet={image.small.url || image.url}
                        />
                        <source
                            media="(max-width: 1045px)"
                            srcSet={image.medium.url || image.url}
                        />
                        <img
                            alt={image.alt}
                            className="tw-w-full"
                            src={image.url}
                        />
                    </picture>
                </a>
            </PromotionTracker>
        </ResponsiveImagePromoStyles>
    );
};
