import styled from '@emotion/styled';
import {colors} from '../ui/theme';

export const HomePageWidgetStyles = styled.div`
    .card-list {
        &.content-scroll {
            height: 100%;
            max-height: 365px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        li {
            padding: 8px;
            border-bottom: 1px solid ${colors.gray[150]};

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
`;
