import * as React from 'react';

import useRecommendations from '../ui/item-spinner/use-recommendations';
import {HeroBanner} from './HeroBanner';
import {HomePageContent} from '../cms/cms.types';
import {ItemSpinner} from '../ui/item-spinner/ItemSpinner';
import {MonetateService} from '../../client/monetate/monetate.service';
import {TopCategories} from './TopCategories';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';
import {ValueAddBanners} from './ValueAddBanners';
import {Zone4} from './Zone4';

interface LoggedOutSectionsProps {
    homePageContent: HomePageContent;
}

export const LoggedOutSections = ({homePageContent}: LoggedOutSectionsProps) => {
    const monetateService: MonetateService = useService(`monetateService`);
    const recommendations = useRecommendations(
        {
            scheme: `home3_rr`,
        },
        monetateService,
    );

    /**
     * Template
     */
    if (!homePageContent) {
        return <></>;
    }
    return (
        <>
            <HeroBanner hero_banner={homePageContent?.hero_banner} />
            <ValueAddBanners value_add_banners={homePageContent?.value_add_banners} />
            <Zone4 zone_4_banners={homePageContent?.zone_4_banners} />
            <TopCategories
                categories={homePageContent?.top_safety_categories}
                id="topSafetyCategoriesContainer"
                title="Top Safety Categories"
            />
            <TopCategories
                categories={homePageContent?.top_fleet_categories}
                id="topFleetCategoriesContainer"
                title="Top Fleet Categories"
            />
            <TopCategories
                categories={homePageContent?.top_facilities_categories}
                id="topFacilitiesCategoriesContainer"
                title="Top Facilities Categories"
            />
            {recommendations.items?.length > 0 && (
                <div id="reactSpinner">
                    <ItemSpinner
                        gaList={`scheme_${recommendations.schemeName}`}
                        label={recommendations.schemeExplanation}
                        loaded={recommendations.loaded}
                        maxSlides={7}
                        orderableItems={recommendations.items}
                        useAddToOrderModal={true}
                        user={new User({})}
                    />
                </div>
            )}
        </>
    );
};
