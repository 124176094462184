import * as React from 'react';
import {faAngleUp, faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode, useState} from 'react';

export interface CollapseZoneProps {
    children: ReactNode;
    desktopOnly?: boolean;
    mobileOnly?: boolean;
    title: string;
    titleLink?: ReactNode;
}

export const CollapseZone = ({children, desktopOnly, mobileOnly, title, titleLink}: CollapseZoneProps) => {
    const [openMobile, setOpenMobile] = useState(false);

    /**
     * Template
     */
    return (
        <div
            className={`${desktopOnly ? 'tw-hidden md:tw-block ' : ''}${
                mobileOnly ? 'tw-block md:tw-hidden ' : ''
            }tw-mb-2 md:tw-mb-6 tw-bg-white tw-border md:tw-border-0 tw-p-4 md:tw-p-0 md:tw-mt-0`}
        >
            {!mobileOnly && (
                <div className="tw-hidden md:tw-block">
                    <h4 className="h4-lockup">
                        {title}
                        {titleLink}
                    </h4>
                </div>
            )}
            {!desktopOnly && (
                <div
                    className="tw-flex md:tw-hidden tw-font-bold tw-my-2 tw-justify-between tw-items-center"
                    onClick={() => {
                        setOpenMobile(!openMobile);
                    }}
                >
                    <div>{title}</div>
                    <FontAwesomeIcon
                        icon={openMobile ? faAngleUp : faAngleDown}
                        size="xl"
                        suppressHydrationWarning
                    />
                </div>
            )}
            <div className={`${openMobile ? 'tw-block' : 'tw-hidden'} md:tw-block`}>{children}</div>
        </div>
    );
};
