import * as React from 'react';

import PromotionTracker from '../analytics/promotion-tracker/PromotionTracker';
import {CollapseZone} from './CollapseZone';
import {WebcatMeta} from '../items/item.class';

export interface HomeCategoriesProps {
    desktopOnly?: boolean;
    mobileOnly?: boolean;
    widgetCMSCategories: WebcatMeta[];
    widgetCMSCategoriesMarket: string;
    widgetCMSCategoriesName: string;
    widgetCMSCategoriesTitle: string;
}

export const HomeCategoriesWidget = ({
    desktopOnly,
    mobileOnly,
    widgetCMSCategories,
    widgetCMSCategoriesMarket,
    widgetCMSCategoriesName,
    widgetCMSCategoriesTitle,
}: HomeCategoriesProps) => (
    <CollapseZone
        desktopOnly={desktopOnly}
        mobileOnly={mobileOnly}
        title={widgetCMSCategoriesTitle}
    >
        <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-6 tw-gap-4">
            {widgetCMSCategories.map((cat, index) => (
                <div
                    className="tw-border tw-h-full tw-p-3"
                    key={index}
                >
                    <PromotionTracker
                        promotion={{
                            creative_name: `Custom Categories Widget - ${cat.name}`,
                            creative_slot: index + 1,
                            promotion_id: widgetCMSCategoriesMarket,
                            promotion_name: widgetCMSCategoriesName,
                        }}
                    >
                        <a
                            className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center"
                            href={`/browse/${cat.categoryPath}`}
                        >
                            <img
                                alt={cat.name}
                                className="tw-max-w-full tw-h-auto tw-my-3"
                                height="120"
                                src={`/ProductImageThumbs240${cat.imagePath || '/noimage.png'}`}
                                width="120"
                            />
                            <div>{cat.name}</div>
                        </a>
                    </PromotionTracker>
                </div>
            ))}
        </div>
    </CollapseZone>
);
