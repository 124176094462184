import * as React from 'react';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {truncate} from 'lodash';

import {HomePageWidgetStyles} from './HomePageWidget.styles';
import {OrderHeader} from '../orders/order-header.class';
import {TemplateHelpers} from '../tools/template-helpers.class';

interface OrdersWidgetProps {
    getOrdersErr: string;
    orderHeaders: OrderHeader[];
}

export const OrdersWidget = ({getOrdersErr, orderHeaders}: OrdersWidgetProps) => {
    return (
        <HomePageWidgetStyles>
            <div
                className="card !tw-h-full !tw-hidden md:!tw-block tw-mb-0"
                id="orders-widget-container"
            >
                <div className="card-header !tw-bg-transparent tw-flex tw-justify-between tw-items-center !tw-px-2">
                    <h4 className="!tw-m-0">My Orders</h4>
                    <div>
                        {orderHeaders?.length > 0 && (
                            <>
                                <a href="/orders">Open</a>&nbsp;|&nbsp;
                            </>
                        )}
                        <a href="/order-history">History</a>
                    </div>
                </div>
                <div className="card-list content-scroll tw-block">
                    {getOrdersErr && <div className="abs-center tw-text-red-500">{getOrdersErr}</div>}
                    {orderHeaders.length === 0 && <div className="abs-center tw-text-red-500">You have no recent orders</div>}
                    {!(getOrdersErr || orderHeaders.length === 0) && (
                        <ul>
                            {orderHeaders.map((orderHeader, index) => (
                                <li
                                    className="tw-flex"
                                    key={index}
                                >
                                    <div className="tw-w-8/12">
                                        {orderHeader.type === 'Q' && <div>Quote</div>}
                                        <a href={orderHeader.orderLink}>{orderHeader.ordrNbr}</a>
                                        {orderHeader.po && (
                                            <div className="bPad5 truncate-po maskPII">PO: {truncate(orderHeader.po, {length: 14})}</div>
                                        )}
                                        <div
                                            className="caption"
                                            suppressHydrationWarning={true}
                                        >
                                            {orderHeader.invcDt ? 'Invoiced' : 'Last Updated'}:{' '}
                                            {TemplateHelpers.formatDate(OrderHeader.latestDate(orderHeader))}
                                        </div>
                                    </div>
                                    <div className="tw-w-4/12 tw-text-right">
                                        {orderHeader.trackLink && <a href={orderHeader.trackLink}>Track</a>}
                                        <div className="tw-text-gray-400">
                                            {orderHeader.status === 'Order Declined' ? (
                                                <span className="tw-text-red-500">
                                                    <FontAwesomeIcon
                                                        icon={faExclamationTriangle}
                                                        suppressHydrationWarning
                                                    />
                                                    Declined
                                                </span>
                                            ) : (
                                                orderHeader.status
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </HomePageWidgetStyles>
    );
};
