import * as PrismicDOM from 'prismic-dom';
import * as React from 'react';
import styled from '@emotion/styled';
import {Button} from 'react-bootstrap';

import PromotionTracker from '../analytics/promotion-tracker/PromotionTracker';
import {Slice} from '../cms/cms.types';

const HeroBannerStyle = styled.div`
    .home_hero_aspect {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc(330 / 1400 * 100%);
        @media (max-width: 480px) {
            padding-top: calc(180 / 480 * 100%);
        }

        picture {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    @media (max-width: 767.98px) {
        .btn-lg {
            border-radius: 2px;
            font-size: 1rem;
            line-height: 1.5;
            padding: 0.375rem 0.75rem;
        }
    }
`;

export interface HeroBannerProps {
    hero_banner: Slice;
}

export const HeroBanner = ({hero_banner}: HeroBannerProps) => {
    if (!hero_banner) {
        return <></>;
    }
    return (
        <HeroBannerStyle>
            <div className="tw-mb-4 -tw-mt-2">
                <div className="tw-relative">
                    <div
                        className="tw-border lg:tw-border-0 lg:tw-absolute tw-bg-white tw-pt-4 tw-pb-4 lg:tw-pb-2 xl:tw-pb-4 tw-px-6 lg:tw-ml-6 tw-mb-4 lg:tw-mb-0 lg:tw-w-1/2"
                        style={{zIndex: 1}}
                    >
                        {hero_banner.primary?.rich_text_overlay[0]?.text && hero_banner.primary.rich_text_overlay[0].text.length > 0 && (
                            <div dangerouslySetInnerHTML={{__html: PrismicDOM.RichText.asHtml(hero_banner.primary.rich_text_overlay)}} />
                        )}
                        {hero_banner.items.map((button, index) => (
                            <PromotionTracker
                                as="span"
                                key={index}
                                promotion={{
                                    creative_name: button.promotion_creative,
                                    creative_slot: index + 1,
                                    promotion_id: button.promotion_id,
                                    promotion_name: button.promotion_name,
                                }}
                            >
                                <Button
                                    className={index === 0 ? 'tw-mr-3' : ''}
                                    href={button.button_link}
                                    size="lg"
                                    variant={button.button_type.replace('btn-', '') as any}
                                >
                                    {button.button_title}
                                </Button>
                            </PromotionTracker>
                        ))}
                    </div>
                </div>
                <div className="home_hero_aspect">
                    <picture>
                        <source
                            media="(max-width: 480px)"
                            srcSet={
                                hero_banner.primary.banner_background_image.small.url || hero_banner.primary.banner_background_image.url
                            }
                        />
                        <img
                            alt={hero_banner.primary.banner_background_image.alt}
                            className="tw-w-full"
                            src={hero_banner.primary.banner_background_image.url}
                        />
                    </picture>
                </div>
            </div>
        </HeroBannerStyle>
    );
};
