import * as React from 'react';
import {useEffect, useState} from 'react';

import {ItemSpinner} from '../ui/item-spinner/ItemSpinner';
import {ItemsService} from '../../client/items/items.service';
import {OrderableItem} from '../items/item.class';
import {OrderItemsWorkflow} from '../../client/order-items/order-items.workflow';
import {UserStateService} from '../../client/users/user-state.service';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';

interface PrevPurchasedSpinnerProps {
    user: User;
}

export const PrevPurchasedSpinner = ({user}: PrevPurchasedSpinnerProps) => {
    const [prevPurchasedItems, setPrevPurchasedItems] = useState<OrderableItem[]>();
    const itemsService: ItemsService = useService(`itemsService`);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);

    // Process queued actions for ItemSpinner in LoggedOutSections component
    useEffect(() => {
        if (userStateService.hasPendingAddToOrder(`scheme_home3_rr`)) {
            orderItemsWorkflow.addToOrderModal(userStateService.addToOrderItems, `scheme_home3_rr`, true);
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get PrevPurchased items
    useEffect(() => {
        itemsService
            .getPrevPurchasedItems()
            .then((getPrevPurchasedItemsRes) => {
                setPrevPurchasedItems(getPrevPurchasedItemsRes);
            })
            .catch(() => {
                setPrevPurchasedItems(undefined);
            });
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Template
     */
    return (
        <>
            {prevPurchasedItems?.length > 0 && (
                <div id="reactSpinner">
                    <ItemSpinner
                        gaList="Previously Purchased Slider(home)"
                        label={`Recently Purchased <a href="/order-history-items" class="h6">See All</a>`}
                        loaded={true}
                        maxSlides={6}
                        orderableItems={prevPurchasedItems}
                        useAddToOrderModal={true}
                        user={user}
                    />
                </div>
            )}
        </>
    );
};
